import type { DocumentNode } from 'graphql';
import { useEffect } from 'react';

import { toArray } from 'utils/array';
import { expireQuery } from './useFetchPolicy';

export type FetchPolicyType =
	| 'assignments'
	| 'recognitions'
	| 'announcements'
	| 'profile'
	| 'group'
	| 'events'
	| 'safety-reports'
	| 'newsletters'
	| 'productionRequests'
	| 'resourceReview';

type QUERIES_BY_TYPE = Map<FetchPolicyType, Set<DocumentNode>>;

const SUBSCRIPTIONS_FOR_UPDATE: QUERIES_BY_TYPE = new Map();
const SUBSCRIPTIONS_FOR_EXPIRE_CACHE: QUERIES_BY_TYPE = new Map();

const getQuerySetForType = (queriesByType: QUERIES_BY_TYPE, type: FetchPolicyType): Set<DocumentNode> =>
	queriesByType.get(type) || new Set<DocumentNode>();

function typesToQueries(
	queriesByType: QUERIES_BY_TYPE,
	types: FetchPolicyType | (FetchPolicyType | undefined)[],
): DocumentNode[] {
	const setOfQueries = toArray(types).reduce((acc, type) => {
		if (!type) return acc;
		const queries = getQuerySetForType(queriesByType, type);
		queries.forEach((query) => acc.add(query));
		return acc;
	}, new Set<DocumentNode>());

	return Array.from(setOfQueries.values());
}

/**
 * For integration into Apollo mutations, returns a list of queries to refetch.
 * Note: this also has a side-effect of clearing any useSimpleQuery caches for the given types
 * @param types list of types of data that has been updated
 * @returns list of queries for Apollo to refetch
 */
export const getRefetchQueriesFor = (
	types: FetchPolicyType | (FetchPolicyType | undefined)[],
): { query: DocumentNode }[] => {
	// expire any cached queries in useFetchPolicy / useSimpleQuery
	typesToQueries(SUBSCRIPTIONS_FOR_EXPIRE_CACHE, types).forEach(expireQuery);

	// return list of queries to be immediately re-fetched by the mutation (cannot include variables)
	return typesToQueries(SUBSCRIPTIONS_FOR_UPDATE, types).map((query) => ({ query }));
};

const registerQueryFor = (
	queriesByType: QUERIES_BY_TYPE,
	query: DocumentNode,
	types: FetchPolicyType | FetchPolicyType[],
): void => {
	toArray(types).forEach((type) => {
		const queries = getQuerySetForType(queriesByType, type);
		queries.add(query);
		queriesByType.set(type, queries);
	});
};

const unregisterQueryFor = (
	queriesByType: QUERIES_BY_TYPE,
	query: DocumentNode,
	types?: FetchPolicyType | FetchPolicyType[],
): void => {
	toArray(types || queriesByType.keys()).forEach((type) => {
		const queries = getQuerySetForType(queriesByType, type);
		queries.delete(query);
		queriesByType.set(type, queries);
	});
};

const registerRefetchQueryFor = (query: DocumentNode, types: FetchPolicyType | FetchPolicyType[]): void => {
	registerQueryFor(SUBSCRIPTIONS_FOR_UPDATE, query, types);
};

const unregisterRefetchQueryFor = (query: DocumentNode, types?: FetchPolicyType | FetchPolicyType[]): void => {
	unregisterQueryFor(SUBSCRIPTIONS_FOR_UPDATE, query, types);
};

const registerExpireQueryFor = (query: DocumentNode, types: FetchPolicyType | FetchPolicyType[]): void => {
	registerQueryFor(SUBSCRIPTIONS_FOR_EXPIRE_CACHE, query, types);
};

const unregisterExpireQueryFor = (query: DocumentNode, types?: FetchPolicyType | FetchPolicyType[]): void => {
	unregisterQueryFor(SUBSCRIPTIONS_FOR_EXPIRE_CACHE, query, types);
};

export function useRegisterForRefetch(
	QUERY: DocumentNode,
	optional: { refetchOn?: FetchPolicyType[]; expireOn?: FetchPolicyType[] } = {},
) {
	const { expireOn, refetchOn } = optional;

	useEffect(() => {
		if (expireOn) {
			expireOn.forEach((type) => registerExpireQueryFor(QUERY, type));
		}
		if (refetchOn) {
			refetchOn.forEach((type) => registerRefetchQueryFor(QUERY, type));
		}
	}, [QUERY, expireOn, refetchOn]);

	// should we unload? could this lead to issues when changing pages and some unload/load?
	return () => {
		if (expireOn) {
			expireOn.forEach((type) => unregisterExpireQueryFor(QUERY, type));
		}
		if (refetchOn) {
			refetchOn.forEach((type) => unregisterRefetchQueryFor(QUERY, type));
		}
	};
}
