import { gql } from '@apollo/client';

// Limited user info for rendering user names
export interface LimitedUserInfoFragment {
	username: string;
	firstname?: string;
	lastname?: string;
	fullName?: string;
}

export const LIMITED_USER_INFO_FRAGMENT = gql`
	fragment LimitedUserInfoFragment on User {
		username
		firstname
		lastname
		fullName @client
	}
`;

// Manager-limited user info for rendering users, includes email
export interface ManagerLimitedUserInfoFragment extends LimitedUserInfoFragment {
	email: string;
	email2?: string;
	groups?: string[];
}

export const MANAGER_LIMITED_USER_INFO_FRAGMENT = gql`
	${LIMITED_USER_INFO_FRAGMENT}
	fragment ManagerLimitedUserInfoFragment on User {
		...LimitedUserInfoFragment
		email
		email2
		groups
	}
`;

// Partial user info some non-manager use cases
export interface PartialUserInfoFragment extends LimitedUserInfoFragment {
	deactivated?: boolean; // including deactivated to be able to render in some situations, hide in others
	groups?: string[]; // used in send recognitions for filtering
	teamIdForView?: string; // user selected group for home page (and other) views
	primaryUnit?: string;
}

export const PARTIAL_USER_INFO_FRAGMENT = gql`
	${LIMITED_USER_INFO_FRAGMENT}
	fragment PartialUserInfoFragment on User {
		...LimitedUserInfoFragment
		deactivated
		groups
		teamIdForView
		primaryUnit @client
	}
`;

export interface PhoneNumberSet {
	number: string;
	label: string;
	deactivated?: boolean;
}

// Full user info for managers (TODO: enforce field restriction in API)
export interface UserInfoFragment extends PartialUserInfoFragment {
	analyticsUserId?: string;
	phoneNumbers?: PhoneNumberSet[];
}

export const USER_INFO_FRAGMENT = gql`
	${PARTIAL_USER_INFO_FRAGMENT}
	fragment UserInfoFragment on User {
		...PartialUserInfoFragment
		analyticsUserId
		phoneNumbers {
			number
			label
			deactivated
		}
	}
`;

// UserInfo + a few fields for Analytics identity
export interface UserIdentityFragment extends UserInfoFragment {
	email: string;
	cognitoUsername?: string;
}

export const USER_IDENTITY_FRAGMENT = gql`
	${USER_INFO_FRAGMENT}
	fragment UserIdentityFragment on User {
		...UserInfoFragment
		email
		cognitoUsername
	}
`;

export function getPrimaryTeamId(user: PartialUserInfoFragment) {
	return user?.primaryUnit || user?.groups?.[0];
}

export function getTeamIdForView(user: PartialUserInfoFragment) {
	return user?.teamIdForView || getPrimaryTeamId(user);
}
