import { DocumentNode } from 'graphql';
import { useQuery } from '@apollo/client'; // BaseQueryOptions, QueryLazyOptions

import { AnalyticsEventOrEvents, useAnalytics } from 'analytics/useAnalytics';

import { errorToString } from 'utils/errorToString';

import { SimpleUseQueryResult } from './SimpleGqlHookTypes';
import logger from 'utils/logger';
import { useFetchPolicyTTL } from './useFetchPolicy';
import { FetchPolicyType, useRegisterForRefetch } from './refetch';
import { useMemo } from 'react';

/**
 * Wrappers for Apollo query hooks to keep query-names contained to the hooks, where appropriate
 *
 * @example
 * type QueryData { item }
 * type Query { queryName: QueryData }
 * type QueryOptions { id }
 *
 * const useItemQuery : SimpleUseQuery<Data, Vars> = (vars) => {
 *   const {data} = useQuery(GQL);
 *   return { data: data?.queryName }
 * }
 *
 * const useItemQuery = (vars:type) : SimpleUseQueryResult<Data> => {
 *   const {data} = useQuery(GQL);
 *   return { data: data?.queryName }
 * }
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface SimpleUseQueryVars<TData = any, TVars = any> {
	(variables: TVars): SimpleUseQueryResult<TData>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface SimpleUseQueryNoVars<TData = any> {
	(): SimpleUseQueryResult<TData>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SimpleUseQuery<TData = any, TVars = undefined | any> = [TVars] extends [undefined]
	? SimpleUseQueryNoVars<TData>
	: SimpleUseQueryVars<TData, TVars>;

interface OptionalSettings<Data> {
	successEvent?: (data: Data) => AnalyticsEventOrEvents;
	errorEvent?: (error: string) => AnalyticsEventOrEvents;
	noCache?: boolean;
	skip?: boolean;
	preferCache?: boolean;
	refetchOn?: FetchPolicyType[]; // refetch on these events
	expireOn?: FetchPolicyType[]; // expires cache on these events (like a deferred process)
}
interface OptionalSettingsWithVars<Data, Vars> {
	successEvent?: (data: Data, variables?: Vars) => AnalyticsEventOrEvents; // TODO: typescript fix to require vars and cast in code?
	errorEvent?: (error: string, variables?: Vars) => AnalyticsEventOrEvents;
	noCache?: boolean;
	skip?: boolean;
	preferCache?: boolean;
	expireOn?: FetchPolicyType[]; // queries with vars can only be expired
}

const RETURN_ALL = '-- RETURN ALL --';

/**
 * Simplified useQuery hook that takes a simple gql query and the query name,
 * and returns a state tuple.
 *
 * @param QUERY gql
 * @param QUERY_NAME string
 * @param {object} variables Optional variable object
 * @param {object} optional Optional settings
 * @param {object} optional.successEvent AnalyticsEvent to log in case of success (passed resulting data and variables)
 * @param {object} optional.errorEvent AnalyticsEvent to log in case of failure (passed resulting error and variables)
 * @param {object} optional.preferCache Use cache if possible (useful for sub-components, to avoid re-fetching mid-render)
 * @param {object} optional.noCache Don't use cache (for frequently changing data)
 * @param {object} optional.skip Defer query if false (passthrough to Apollo skip param)
 * @returns {data, loading:boolean, error:Error}
 *
 * @example
 * const MY_QUERY = gql`query wrapper($id: number!) { myQuery(id:$id) { success } }`
 * type MyData = { success: boolean }
 * type MyVars = { id: number }
 * const myVars = { id: 1 }
 * const {data:MyData, error, loading} = useSimpleQuery<MyData,MyVars>(MY_QUERY, 'myQuery', myVars)
 * // data = { success: true }
 */

export function useSimpleQuery<Data>(QUERY: DocumentNode, QUERY_NAME: string): SimpleUseQueryResult<Data>;
export function useSimpleQuery<Data>(
	QUERY: DocumentNode,
	QUERY_NAME: string,
	variables: undefined,
	optional: OptionalSettings<Data>,
): SimpleUseQueryResult<Data>;
export function useSimpleQuery<Data, Vars>(
	QUERY: DocumentNode,
	QUERY_NAME: string,
	variables: Vars,
	optional?: OptionalSettingsWithVars<Data, Vars>,
): SimpleUseQueryResult<Data>;
// eslint-disable-next-line sonarjs/cognitive-complexity
export function useSimpleQuery<Data, Vars>(
	QUERY: DocumentNode,
	QUERY_NAME: string,
	variables?: Vars,
	optional: OptionalSettings<Data> | OptionalSettingsWithVars<Data, Vars> = {},
): SimpleUseQueryResult<Data> {
	const { track } = useAnalytics();

	const { getFetchPolicy, setLastUpdate, clearLastUpdate } = useFetchPolicyTTL(
		QUERY,
		variables as Record<string, any>,
	);

	useRegisterForRefetch(QUERY, optional);

	if (!QUERY_NAME) {
		throw new Error('useSimpleQuery name should not be empty');
	}

	const { successEvent, errorEvent, noCache, skip, preferCache } = optional;

	const { data, error, loading, refetch } = useQuery(QUERY, {
		fetchPolicy: preferCache ? 'cache-first' : noCache ? 'network-only' : getFetchPolicy(),
		skip,

		onCompleted: (data) => {
			setLastUpdate();

			if (successEvent) {
				track(successEvent(QUERY_NAME === RETURN_ALL ? data : data?.[QUERY_NAME], variables));
			}
		},

		onError: (err) => {
			clearLastUpdate();

			// TODO : common error handling?
			logger.error(err);
			if (errorEvent) {
				track(errorEvent(errorToString(err), variables));
			}
		},

		...(variables ? { variables } : {}),
	});

	return useMemo(
		() => ({ data: QUERY_NAME === RETURN_ALL ? data : data?.[QUERY_NAME], error, loading, refetch }),
		[QUERY_NAME, data, error, loading, refetch],
	);
}

export function useSimpleMultiQuery<Data>(QUERY: DocumentNode): SimpleUseQueryResult<Data>;
export function useSimpleMultiQuery<Data>(
	QUERY: DocumentNode,
	variables: undefined,
	optional: OptionalSettings<Data>,
): SimpleUseQueryResult<Data>;
export function useSimpleMultiQuery<Data, Vars>(
	QUERY: DocumentNode,
	variables: Vars,
	optional?: OptionalSettingsWithVars<Data, Vars>,
): SimpleUseQueryResult<Data>;
export function useSimpleMultiQuery<Data, Vars>(
	QUERY: DocumentNode,
	variables?: Vars,
	optional?: OptionalSettings<Data> | OptionalSettingsWithVars<Data, Vars>,
): SimpleUseQueryResult<Data> {
	return useSimpleQuery(QUERY, RETURN_ALL, variables || (undefined as unknown as Vars), optional);
}
