import { useMemo } from 'react';
import { gql } from '@apollo/client';

import { SimpleMutation, useSimpleMutation } from 'gql/useSimpleMutation';

// https://filestack.github.io/filestack-js/interfaces/pickerfilemetadata.html

type FileStackMetadataInput = 'Icon' | 'Media' | 'Resource' | 'Source';

export enum UploadTypes {
	Icon = 'Icon',
	Media = 'Media',
	Resource = 'Resource',
	Source = 'Source',
	Announcement = 'Announcement',
	ProductionRequest = 'ProductionRequest',
	Newsletter = 'Newsletter',
}

interface FileUploadRecord {
	container: string;
	filename: string;
	handle: string;
	key: string;
	mimetype: string;
	size: number;
	source: string;
	url: string;
}

export interface LogFileUploadsVariables {
	type: FileStackMetadataInput;
	siteId: string;
	resourceId: string;
}

export type LogFileUploadsMutationVariables = any[];

interface LogFileUploadsInnerVariables {
	type: FileStackMetadataInput;
	siteId: string;
	resourceId: string;
	files: FileUploadRecord[];
}

/**
 * Assignment creation
 * Note: FileStackMetadataInput and FileUploadType are GQL types on the server
 */
const MUTATION_LOG_FILE_UPLOADS = gql`
	mutation ($siteId: String!, $resourceId: String, $type: FileUploadType!, $files: [FileStackMetadataInput]) {
		logFileUploads(siteId: $siteId, resourceId: $resourceId, type: $type, metadatas: $files) {
			message
		}
	}
`;

function filterMetadata(files: any[]): FileUploadRecord[] {
	return files.map(({ container, filename, handle, key, mimetype, size, source, url }) => ({
		container,
		filename,
		handle,
		key,
		mimetype,
		size,
		source,
		url,
	}));
}

export const useLogFileUploads: SimpleMutation<void, LogFileUploadsMutationVariables> = ({
	type,
	siteId,
	resourceId,
}: LogFileUploadsVariables) => {
	const [mutationFunction, status] = useSimpleMutation<void, LogFileUploadsInnerVariables>(
		MUTATION_LOG_FILE_UPLOADS,
		'logFileUploads',
	);

	const logFileUploadFn = useMemo(() => {
		// Local mutation overload to conform with SimpleMutation typing
		function _logFn(variables: LogFileUploadsMutationVariables, options: { noAwait: true }): void;
		function _logFn(variables: LogFileUploadsMutationVariables, options?: { noAwait?: false }): Promise<void>;
		function _logFn(variables: LogFileUploadsMutationVariables, options?: { noAwait?: boolean }) {
			const props = { type, siteId, resourceId, files: filterMetadata(variables) };
			return options?.noAwait ? mutationFunction(props, { noAwait: true }) : mutationFunction(props);
		}
		return _logFn;
	}, [mutationFunction, resourceId, siteId, type]);

	return [logFileUploadFn, status];
};
