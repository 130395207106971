import { isProd, isOffline, isTest, STAGE } from 'helpers/constants';

const baseSiteIndicatorBarStyle = {
	position: 'fixed',
	top: 0,
	left: 0,
	right: 0,
	height: '0.25rem',
	backgroundColor: 'yellow',
	zIndex: 1000000,
	textAlign: 'center',
	pointerEvents: 'none',
} as React.CSSProperties;

const baseSiteIndicatorBoxStyle = {
	position: 'relative',
	top: '-0.125rem',
	display: 'inline-block',
	backgroundColor: 'yellow',
	padding: '0 0.938rem 0.125rem',
	borderBottomLeftRadius: '8px',
	borderBottomRightRadius: '8px',
	fontSize: '0.75rem',
	zIndex: 1000001,
} as React.CSSProperties;

const welcomeSiteIndicatorBarStyle = { ...baseSiteIndicatorBarStyle, backgroundColor: 'orange' };
const welcomeSiteIndicatorBoxStyle = { ...baseSiteIndicatorBoxStyle, backgroundColor: 'orange' };

const WELCOME_MESSAGE =
	"This is the sandbox site for the Elemeno App. Use this space to try out features and read our Site Manager's Guide.";

export function SiteIndicator({ siteId }: { siteId?: string }): JSX.Element | null {
	if (isTest) {
		return null;
	}

	const isWelcome = siteId === 'welcome';
	if (isProd && !isWelcome) {
		return null;
	}

	let barStyle = baseSiteIndicatorBarStyle;
	let boxStyle = baseSiteIndicatorBoxStyle;
	let message = `${isOffline ? 'offline' : STAGE}: ${window.SITE_ID}`;
	if (isWelcome) {
		barStyle = welcomeSiteIndicatorBarStyle;
		boxStyle = welcomeSiteIndicatorBoxStyle;
		message = WELCOME_MESSAGE;
	}
	return (
		<div style={barStyle}>
			<div style={boxStyle}>{message}</div>
		</div>
	);
}
