import { ThemeConfig } from '@chakra-ui/react';

export const THEME_CONFIG: ThemeConfig = {
	cssVarPrefix: 'elemeno',
	initialColorMode: 'light',
	// Update based on system preferences
	useSystemColorMode: false,
};

export const BREAKPOINT = {
	MOBILE: 0,
	MOBILE_MD: 450,
	TABLET: 600,
	TABLET_MD: 768,
	DESKTOP: 1024,
	DESKTOP_SM: 1366,
	DESKTOP_MD: 1536,
	DESKTOP_LG: 1920,
};

export const BREAKPOINT_VALUES = {
	mobile: `${BREAKPOINT.MOBILE}px`,
	mobileMd: `${BREAKPOINT.MOBILE_MD}px`,
	tablet: `${BREAKPOINT.TABLET}px`,
	tabletMd: `${BREAKPOINT.TABLET_MD}px`,
	desktop: `${BREAKPOINT.DESKTOP}px`,
	desktopSm: `${BREAKPOINT.DESKTOP_SM}px`,
	desktopMd: `${BREAKPOINT.DESKTOP_MD}px`,
	desktopLg: `${BREAKPOINT.DESKTOP_LG}px`,
};

export const JW_PLAYER_LIBRARY_URL = 'https://cdn.jwplayer.com/libraries/txjgVV7g.js';
export const APP_BG_COLOR = '#F6F8FA';
