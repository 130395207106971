import { createContext, FC, ReactElement, useCallback, useContext, useMemo } from 'react';

import { TEST_SITE } from 'helpers/test/testConstants';

import { analyticsTrack } from 'analytics/AnalyticsService';
import { AnalyticsEvents } from 'analytics/AnalyticsEvents';

import {
	CLIENT_FEATURE_FLAGS,
	SiteConfigInterface,
	SiteMode,
	SiteType,
	useSiteConfigQuery,
} from 'hooks/useSiteConfigQuery';

import { ChildrenProps } from 'types/common';
import LocalStore from 'utils/LocalStore';
import { fixContentUrlForOffline } from 'utils/files';
import { isOffline, isProd, SSO_REDIRECT_URL } from 'helpers/constants';
import { SiteIndicator } from './SiteIndicator';
import { handlePromise } from 'utils/async';

declare global {
	interface Window {
		SITE_ID: string; // permanent site id/name
		ALT_SITE_NAME?: string; // configurable alternate site name
		SITE_NAME: string; // primary site name (alt or id)
		SITE_DISPLAY_NAME: string; // name to display (display name or alt or id)
		SITE_NAME_SUMMARY: string; // display name and id (if different)
		SITE_TYPE: string;
		SSO_CLIENT_ID?: string;
		SSO_URL?: string;
	}
}

/**
 * Wrapping pre-themed loader from index.html.
 * Since we don't load the theme immediately for setup, and Loader relies on that theme,
 * this "preloading" Loader is used to provide a consistent loader with the theme.
 * @param params.message string message to display
 * @returns JSX.Element
 */
export function PreLoading({ message }: { message: string }): JSX.Element {
	return (
		<div
			style={{ position: 'relative' }}
			// loadingHtml check needed for testing
			dangerouslySetInnerHTML={{ __html: window.loadingHtml ? window.loadingHtml(message) : '<i>Loading...</i>' }}
		/>
	);
}

const _launchData = {
	windowWidth: window?.innerWidth || 0,
	windowHeight: window?.innerHeight || 0,
	referer: document?.referrer || '',
};

/**
 * Given a site config, store some data in the global space for access outside of react.
 * @param data SiteConfig
 */
function setupStaticInfo(data): void {
	// expose outside of react for API code
	window.SITE_ID = data.siteId;
	window.ALT_SITE_NAME = data.alternateSiteName;
	window.SITE_NAME = data.alternateSiteName || data.siteId;
	window.SITE_DISPLAY_NAME = data.displayName || window.SITE_NAME;
	window.SITE_NAME_SUMMARY =
		window.SITE_DISPLAY_NAME +
		(window.SITE_DISPLAY_NAME.toLowerCase() !== window.SITE_NAME ? ` (${window.SITE_NAME})` : '');

	window.SSO_URL = data.sso?.cognito_url;
	window.SSO_CLIENT_ID = data.sso?.client_id;
	window.SITE_TYPE = data.type;

	document
		.querySelector('meta[name="description"]')
		?.setAttribute('content', 'Elemeno Health @ ' + window.SITE_DISPLAY_NAME);
}

/**
 * Gets the current site name/id from either the url or configuration.
 * In most cases this will be the id, but in some cases this will be the
 * 'alternate' site name that will be used to get the site id from the config.
 * @returns string name, which may be the id
 */
export function resolveSiteName(): string {
	const hostName = window.location.hostname;

	if (isOffline && import.meta.env.VITE_SITE_ID) {
		return import.meta.env.VITE_SITE_ID;
	}

	// prs special handling
	if (hostName === 'prs.lmno.care') {
		// https://prs.lmno.care/setup-semaphore/
		return 'dev';
	}

	let siteName = hostName.split('.')[0];

	// assume all numeric addresses are localhost
	if (siteName.match(/^[0-9]+$/)) {
		siteName = 'localhost';
	}

	// to use add VITE_SITE_ID=siteid in .env.local
	if (siteName === 'localhost' && import.meta.env.VITE_SITE_ID) {
		return import.meta.env.VITE_SITE_ID;
	}

	// hack for running two sites locally
	if (siteName === 'localhost' && window.location.port === '8081') {
		siteName = 'localhost2';
	}

	return siteName;
}

const RAW_SITE_NAME = resolveSiteName(); // may be id, but config will specify

// I don't think this is needed anymore
// // eslint-disable-next-line sonarjs/cognitive-complexity
// export function cleanUpRoute(location: Location | undefined = undefined): string | undefined {
// 	const { hostname, pathname } = location || window.location;
// 	if (pathname !== '/') {
// 		let newPath = pathname;

// 		if (hostname?.includes('.prs.')) {
// 			// special handling for pr path
// 			const match = /^\/([^/]+)\/(.*)/.exec(pathname);
// 			if (match?.[2]) {
// 				// has extra path
// 				newPath = `/${match[1]}/${match[2]}`;
// 			} else {
// 				// doesn't have extra path
// 				return;
// 			}
// 		}

// 		if (location) {
// 			// must be testing
// 			return newPath;
// 		} else if (newPath !== pathname) {
// 			logger.info('cleanUpRoute: REPLACE STATE', newPath);
// 			window.history.replaceState('', '', newPath);
// 		}
// 	}
// }

// cleanUpRoute();

// Typescript workaround to ensure siteId isn't undefined (since nothing using this would get it if it was.)
const EmptySite = {
	siteId: 'NOT LOADED',
	name: 'NOT LOADED',
	type: SiteType.Customer,
	mode: SiteMode.Standard,
	showElemeno: false,
	setAppIcon: () => null,
	hasFlag: () => false,
}; // used in tests but will be overwritten before anything is rendered

interface SiteContextInterface extends SiteConfigInterface {
	name: string;
	setAppIcon: (isElemeno: boolean) => void;
	redirect_uri?: string;
	hasFlag: (flag: CLIENT_FEATURE_FLAGS | CLIENT_FEATURE_FLAGS[]) => boolean;
	isPublishingSite?: boolean;
}

const InnerSiteContext = createContext<SiteContextInterface>(EmptySite);

const notFoundStyle = {
	width: '50%',
	margin: '2rem auto',
	background: '#fff',
	padding: '2rem',
	textAlign: 'center' as const,
	borderRadius: '0.5rem',
};

const BasicError: FC<ChildrenProps> = ({ children }) => (
	<div className="home-loading">
		<div className="home-loading-logo" dangerouslySetInnerHTML={{ __html: window.elemenoSvg }} />
		<div style={notFoundStyle}>{children}</div>
	</div>
);

interface SiteContextProps {
	render: () => ReactElement;
}

export const SiteContext: FC<SiteContextProps> = ({ render, ...props }) => {
	const { data, error, loading } = useSiteConfigQuery(RAW_SITE_NAME);

	/**
	 * Conditionally set appicon based on stage and user
	 */
	const setAppIcon = useCallback(
		(isElemeno) => {
			const appiconElement = window.document.getElementById('el-app-icon') as HTMLLinkElement;
			if (!appiconElement) {
				return;
			} else if (isOffline) {
				// blue
				handlePromise(
					import('img/favicon/favicon-48-dev.png').then((src) => (appiconElement.href = src.default)),
				);
			} else if (!isProd) {
				// purple
				handlePromise(
					import('img/favicon/favicon-48-stage.png').then((src) => (appiconElement.href = src.default)),
				);
			} else if (isElemeno && data?.appicon) {
				// show customer icon for elemeno users
				appiconElement.href = fixContentUrlForOffline(data.appicon);
			} else {
				// show standard icon
				handlePromise(import('img/favicon/favicon-48.png').then((src) => (appiconElement.href = src.default)));
			}
		},
		[data?.appicon],
	);

	const hasFlag = useCallback(
		(flagOrFlags: CLIENT_FEATURE_FLAGS | CLIENT_FEATURE_FLAGS[]) =>
			(Array.isArray(flagOrFlags) ? flagOrFlags : [flagOrFlags]).some(
				(flag) => !!data?.clientFeatureFlags?.includes(flag),
			),
		[data?.clientFeatureFlags],
	);

	useMemo(() => {
		// initial load icon setting (mainly for dev/stage)
		setAppIcon(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// eslint-disable-next-line sonarjs/cognitive-complexity
	return useMemo(() => {
		if (error) {
			analyticsTrack(AnalyticsEvents.launch({ site: RAW_SITE_NAME, error: error.message, ..._launchData }));
			return (
				<BasicError>
					There was an error loading the site, please{' '}
					<button
						// style={{ color: 'rgb(51, 151, 215)' }}
						onClick={(e) => {
							e.preventDefault();
							window.location.reload();
							return false;
						}}
					>
						reload to try again
					</button>
					<br />
					<br />({error.message})
				</BasicError>
			);
		} else if (loading) {
			return <PreLoading message="Loading Elemeno..." />;
		} else if (!data?.siteId) {
			analyticsTrack(AnalyticsEvents.launch({ site: RAW_SITE_NAME, invalidSite: true, ..._launchData }));
			analyticsTrack(AnalyticsEvents.alertBadSite({ site: RAW_SITE_NAME }));
			return (
				<BasicError>
					<b>404: site not found</b>
				</BasicError>
			);
		} else {
			setupStaticInfo(data);

			analyticsTrack(AnalyticsEvents.launch({ site: RAW_SITE_NAME, siteId: data.siteId, ..._launchData }));

			// if (!!data.custom?.assignments && !data.custom.assignments.path) {
			// 	data.custom.assignments.path = PATH_FORYOU;
			// }

			// setup sso redirect_uri, only used on system sites with SSO
			const redirect_uri =
				data.parentSite && !data.simpleSystemRedirects
					? SSO_REDIRECT_URL.replace(RAW_SITE_NAME, data.parentSite)
					: undefined;

			const context = {
				...data,
				setAppIcon,
				hasFlag,
				redirect_uri,
				name: data.displayName || RAW_SITE_NAME,
				isPublishingSite: data.mode === SiteMode.Publishing,
				isSystemSite: data.isSystemSite || data.mode === SiteMode.System,
			};

			if (hasFlag(CLIENT_FEATURE_FLAGS.USE_AUTH_SESSION_TOKENS)) {
				if (!LocalStore.SsoKeepMeLoggedIn.get()) {
					// if "keep me logged in" then use local storage for magic tokens
					LocalStore.Token.setStorageMode('session');
				}
				LocalStore.TokenSSO.setStorageMode('session');
				LocalStore.TokenCredentials.setStorageMode('session');
			}

			return (
				<InnerSiteContext.Provider value={context} {...props}>
					<SiteIndicator siteId={data.siteId} />
					{render()}
				</InnerSiteContext.Provider>
			);
		}
	}, [data, error, hasFlag, loading, props, render, setAppIcon]);
};

export interface MockSiteDataProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data?: Record<string, any>;
}

export const MockSiteContext: FC<MockSiteDataProps & ChildrenProps> = ({ data = {}, ...props }) => {
	return (
		<InnerSiteContext.Provider
			value={{
				siteId: TEST_SITE,
				name: TEST_SITE,
				type: SiteType.Testing,
				mode: SiteMode.Standard,
				setAppIcon: () => null,
				hasFlag: () => false,
				...data,
			}}
			{...props}
		/>
	);
};

export const useSiteContext = (): SiteContextInterface => useContext(InnerSiteContext);
