import { forwardRef } from 'react';

import {
	Input as ChakraInput,
	InputGroup,
	InputLeftAddon,
	InputLeftElement,
	InputRightAddon,
	InputRightElement,
} from '@chakra-ui/react';
import { FormControl, FormControlProps, SystemProps, popFormControlProps } from '@elemeno/ui';

import { InputProps, InputDefaultProps } from './Input.types';

const Input = forwardRef<HTMLInputElement, InputProps>(
	({ contentLeft, contentRight, leftElement, rightElement, leftAddon, rightAddon, ...props }, ref) => {
		const { poppedProps, otherProps } = popFormControlProps(props);

		return (
			<FormControl {...(poppedProps as FormControlProps)}>
				<InputGroup>
					{contentLeft ||
						(leftElement && <InputLeftElement>{leftElement}</InputLeftElement>) ||
						(leftAddon && <InputLeftAddon>{leftAddon}</InputLeftAddon>)}
					<ChakraInput ref={ref} {...InputStyles} {...otherProps} />
					{contentRight ||
						(rightElement && <InputRightElement>{rightElement}</InputRightElement>) ||
						(rightAddon && <InputRightAddon>{rightAddon}</InputRightAddon>)}
				</InputGroup>
			</FormControl>
		);
	},
);

const InputStyles: SystemProps = {
	borderColor: 'gray.300',
	background: '#FFF',
};

Input.displayName = 'Input';
Input.defaultProps = InputDefaultProps;

export { Input };
